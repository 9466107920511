
<div *ngIf="pw.id">

  

  <form nz-form [nzLayout]="'vertical'" class="m-d-off" >


    

    <div nz-row  [nzGutter]="bas.ui.gridMd.gutter" nzAlign="top" >

      @if (pw.findTimeAreas?.length > 1) {
        <div nz-col  
        [nzXs]="view.grid.guests.xs" 
        [nzSm]="view.grid.guests.sm" 
        [nzMd]="view.grid.guests.md" 
        [nzLg]="view.grid.guests.lg" 
        [nzXl]="view.grid.guests.xl" 
        [nzXXl]="view.grid.guests.xxl" 
        class=""
   
        >

        <nz-form-item>
          <nz-form-label  class="" nzFor="">{{ 'common.venue' | actrans }}</nz-form-label>
          <nz-form-control [nzErrorTip]="'common.venue'  | actrans | str">
          <div>

            <nz-input-group nzPrefixIcon="pushpin"  [nzSize]="view.size">
          
              <nz-select 
              [(ngModel)]="pw.areaId"
              [ngModelOptions]="{standalone: true}"
              (ngModelChange)="onAreaChange($event)"

              >
              <nz-option 
              [nzValue]="''" 
                [nzLabel]="'--------------'">
              </nz-option>
                <nz-option 
                  *ngFor="let area of pw.findTimeAreas || []" 
                  [nzValue]="area.idAsString" 
                  [nzLabel]="area.mkName">
                </nz-option>
              </nz-select>
            
            </nz-input-group>

        </div>
        </nz-form-control>
        </nz-form-item>

        <!-- <app-select-guests [pw]="pw" [gcs]="pw.guestCounts" (change)="onGuestsUpdate($event)" ></app-select-guests> -->
      </div>
      }

      @if (!opts.hideDateTime) {
      <div nz-col 
        [nzXs]="view.grid.date.xs" 
        [nzSm]="view.grid.date.sm" 
        [nzMd]="view.grid.date.md" 
        [nzLg]="view.grid.date.lg" 
        [nzXl]="view.grid.date.xl" 
        [nzXXl]="view.grid.date.xxl" 
        *ngIf="pw.productObj.tcTime || pw.productObj.tcDate || pw.productObj.tcPeriod"
        >
          
        <nz-form-item>
          <nz-form-label  class="" nzFor="">{{ 'app.cbapi5.common.selectDate' | actrans }}</nz-form-label>
          <nz-form-control [nzErrorTip]="'app.cbapi5.common.selectDate' | actrans | str">
          <div>
            <nz-input-group *ngIf="pw.productObj.tcTime || (pw.productObj.tcDate && pw.productObj.singleDateProduct)" nzPrefixIcon="calendar"  [nzSize]="view.size">
              <!-- <input  
            
     

                [(ngModel)]="pw.startDateAsJsDate"
                name="startDateAsJsDate"
                [ngModelOptions]="{standalone: true}"
           
                mbsc-datepicker
                [mbscOptions]="options.time"
                [controls]="['calendar']"

     
                select="date"
                display="anchored"
                [showOnFocus]="false"
                [invalid]="dateInvalid"
                [colors]="dateColors"
                (onPageLoading)="dateOnPageLoading($event)"
                (onChange)="dateOnChange($event)"

                class="ant-input"
              /> -->
              <!--
                 
                calendarType="week"
                [calendarSize]="2"
              -->

              <nz-date-picker
                [(ngModel)]="_pw.startDateAsJsDate"
                [ngModelOptions]="{standalone: true}"
                [nzAllowClear]="false"
                [nzInputReadOnly]="bas.ui.r.xs"
                [nzDisabledDate]="disabledDate"
                [nzShowToday]="false"
                (ngModelChange)="dateOnChange($event)"
                nzBorderless
                [nzInline]="false"
                class="w-100 no-border"
              ></nz-date-picker>
        
            
              </nz-input-group>

              @if (pw.findTimeArea) {   
                <nz-input-group>
                  <div>
                    <nz-alert class="mt-1" nzType="info" [nzMessage]="('common.venue' | actrans | str) + ': ' + pw.findTimeArea.mkName " nzShowIcon></nz-alert>
                  </div>
                </nz-input-group>
              }

            <nz-input-group *ngIf="pw.productObj.tcDate && !pw.productObj.singleDateProduct" nzPrefixIcon="calendar"  [nzSize]="view.size">
              <!-- <input  
            
                [(ngModel)]="pw.periodArray"
                name="periodArray"
                [ngModelOptions]="{standalone: true}"

                mbsc-datepicker
                [mbscOptions]="options.date"
                [controls]="['calendar']"
     
                select="range"
                display="anchored"
                [showOnFocus]="false"
                
                [rangeStartLabel]="(pw.productObj.findTypeLodging ? 'app.lib.common.rangeStart.acc' : 'app.lib.common.rangeStart') | actrans | str"
                [rangeEndLabel]="(pw.productObj.findTypeLodging ? 'app.lib.common.rangeEnd.acc' : 'app.lib.common.rangeEnd') | actrans | str"

            
                [minRange]="pw.productObj.findMinRange"
                [maxRange]="pw.productObj.findMaxRange"

            
                [invalid]="dateInvalid"
                [colors]="dateColors"
                (onPageLoading)="dateOnPageLoading($event)"
                (onChange)="dateRangeOnChange($event)"

                class="ant-input"
              />  -->
              <!-- 
                [inRangeInvalid]="rangeOptions.inRangeInvalid" -->


                  <nz-range-picker
                  [(ngModel)]="pw.periodArray"
                  [ngModelOptions]="{standalone: true}"
                  [nzAllowClear]="false"
                  [nzInputReadOnly]="bas.ui.r.xs"
                  [nzDisabledDate]="disabledDate"
                  [nzShowToday]="false"
                  (ngModelChange)="dateRangeOnChange($event)"
                  nzBorderless
                  [nzInline]="false"
                  class="w-100 no-border"
                ></nz-range-picker>
          


            </nz-input-group>
            <nz-input-group *ngIf="pw.productObj.tcPeriod" nzPrefixIcon="calendar"  [nzSize]="view.size">
              <!-- <input  
            
                mbsc-datepicker
                [controls]="['calendar']"
     
                select="range"
                display="anchored"

                
                [rangeStartLabel]="'Innsjekk'"
                [rangeEndLabel]="'Utsjekk'"

                [(ngModel)]="pw.periodArray"
                name="periodArray"
                [ngModelOptions]="{standalone: true}"
            
            
                [invalid]="dateInvalid"
                (onPageLoading)="dateOnPageLoading($event)"
                (onChange)="dateRangeOnChange($event)"

                class="ant-input"
              /> -->
              <nz-select 
              [(ngModel)]="pw.productPeriodAsString"
              [ngModelOptions]="{standalone: true}"
                (ngModelChange)="onPeriodChange($event)"

              >
                <nz-option 
                  *ngFor="let pp of pw.productPeriods || []" 
                  [nzValue]="pp.productPeriodAsString" 
                  [nzLabel]="pp.label">
                </nz-option>
              </nz-select>
            
            </nz-input-group>
            <!--TODO: annen visning? -->

          </div>
          </nz-form-control>
      

          
        </nz-form-item>



      </div>
      

      <div nz-col 
        [nzXs]="view.grid.date.xs" 
        [nzSm]="view.grid.date.sm" 
        [nzMd]="view.grid.date.md" 
        [nzLg]="view.grid.date.lg" 
        [nzXl]="view.grid.date.xl" 
        [nzXXl]="view.grid.date.xxl" 
        *ngIf="pw.productObj.tcTime">

        <nz-form-item>
          <nz-form-label  class="" nzFor="">{{ ( (pw.timeItemsAvailable || []).length > 1 ? 'app.cbapi5.common.selectTime' : 'common.time') | actrans }}</nz-form-label>
          <nz-form-control  *ngIf="!pw.productObj.useTimegrid || !pw.timeItemsAvailable?.length" [nzErrorTip]="'app.cbapi5.common.selectTime' | actrans | str">
      
            <nz-input-group nzPrefixIcon="clock-circle"  [nzSize]="view.size" style="padding: 3px 10px;">

              <nz-select 
              [(ngModel)]="pw.timePeriodAsString"
              [ngModelOptions]="{standalone: true}"
              (ngModelChange)="onTimeChange($event)"
              *ngIf="(pw.timeItemsAvailable || []).length > 1"
              >
                <nz-option 
                  *ngFor="let ti of pw.timeItemsAvailable || []" 
                  [nzValue]="ti.timePeriod" 
                  [nzLabel]="ti.timePeriodDisplay">
                </nz-option>
                <!-- <nz-option nzValue="15:30" nzLabel="15:30" nzDisabled></nz-option> -->
              </nz-select>

              <div class="m-1 ml-d" *ngIf="(pw.timeItemsAvailable || []).length <= 1">
                {{ pw.startTimeAsString }}
              </div>
            </nz-input-group>


  
          </nz-form-control>
          <nz-form-control id="fc-timegrid" *ngIf="pw.productObj.useTimegrid && pw.timeItemsAvailable?.length">


            <!-- <div *ngIf="bas.ui.r.ltexs || true">
      
              <nz-input-group *ngFor="let ti of pw.timeItemsAvailable"  nzPrefixIcon="clock-circle"  [nzSize]="view.size" style="padding: 3px 10px;">

                  <nz-select 
        
                    
                      class="" style="width: 48%; margin-left: 2%;"
                      nzDropdownClassName="timegrid-dropdown"
                      [(ngModel)]="timegrid.startTime"
                      [ngModelOptions]="{standalone: true}"
                      (ngModelChange)="onTimegridChange('start', $event)"
                      >
                      <nz-option 
                        *ngFor="let item of timegrid.items" 
                        [nzValue]="item.startTimeAsString" 
                        [nzLabel]="item.startTimeAsString"
                        [nzDisabled]="!item.validStart">
                      </nz-option>

                  </nz-select>
                  <nz-select 
          
                      
                    class="" style="width: 48%; margin-left: 2%;"
                    nzDropdownClassName="timegrid-dropdown"
                    [(ngModel)]="timegrid.endTime"
                    [ngModelOptions]="{standalone: true}"
                    (ngModelChange)="onTimegridChange('end', $event)"
                    >
                    <nz-option 
                      *ngFor="let item of timegrid.items | filterBy: { endTimeAfterStart: true }" 
                      [nzValue]="item.startTimeAsString" 
                      [nzLabel]="item.startTimeAsString"
                      [nzDisabled]="!item.validEnd">
                    </nz-option>
                    <nz-option 
                      *ngIf="timegrid.lastEndItem" 
                      [nzValue]="timegrid.lastEndItem.startTimeAsString" 
                      [nzLabel]="timegrid.lastEndItem.startTimeAsString"
                    >
                    </nz-option>

                </nz-select>
              
            
              </nz-input-group>

            </div> -->
            <div>
              
              <button 
                nz-button 
                nzBlock class="text-left"
                style=""
                nzSize="large"
                (click)="showTimegrid()"
                >
                <span nz-icon nzType="clock-circle"></span>
                <span class="text-lg-off"> {{ timegrid.label | actrans }}</span>
          
              </button>

              <nz-drawer
                nzWrapClassName="drawer-responsive"
                [nzClosable]="true"
                [nzVisible]="timegrid.visible"
                [nzPlacement]="bas.ui.r.ltesm ? 'top' : 'bottom'"
                [nzTitle]="timegrid.label"
                [nzFooter]="footerTpl"
                [nzHeight]="bas.ui.r.h > 690 ? '670px' : '90%'"
                [nzZIndex]="1005"
                (nzOnClose)="timegrid.visible = false;"
                >
                <ng-container *nzDrawerContent >

          
                  <div *ngFor="let ti of pw.timeItemsAvailable" nz-row [nzGutter]="16">
                    <div nz-col [nzXs]="24" [nzSm]="12">
                      <nz-collapse>
                      <nz-collapse-panel [nzHeader]="('common.from' | actrans | str) + ': ' + timegrid.startTime" [nzActive]="timegrid.collapseStart" [nzShowArrow]="false">
                      <!-- <h2>{{ 'common.from' | actrans }}</h2> -->
                      <mbsc-datepicker 
    
                          [controls]="['timegrid']"
                          [display]="'inline'"
                          class="timegrid-timepicker inline-block w-100"
                          style=""
                          [minTime]="ti.startTimeAsString"
                          [maxTime]="ti.endTimeAsString"
                          [stepMinute]="ti.duration"
                          [valid]="timegrid.validStart"
                          [(ngModel)]="timegrid.start"
                          [ngModelOptions]="{standalone: true}"
                          (onChange)="onTimegridChange('start', $event)"
                          >
                      </mbsc-datepicker>

                      <!--

                        [display]="bas.ui.r.gtesm ? 'inline' : 'anchored'"
                      -->
                      </nz-collapse-panel>
                      </nz-collapse>
                    </div> 
                    <div nz-col  [nzXs]="24" [nzSm]="12" [style]="bas.ui.r.gtesm ? 'border-left: 1px black solid;' : ''">
                      <nz-collapse>
                      <nz-collapse-panel [nzHeader]="('common.to' | actrans | str) + ': ' + timegrid.endTime" [nzActive]="timegrid.collapseEnd" [nzShowArrow]="false">
                      <!-- <h2>{{ 'common.to' | actrans }}</h2> -->
                      <mbsc-datepicker 
                        [controls]="['timegrid']"
                        [display]="'inline'"
                        class="timegrid-timepicker inline-block w-100"
                        style=""
                        [minTime]="ti.startTimeAsString"
                        [maxTime]="ti.endTimeAsString"
                        [stepMinute]="ti.duration"
                        [valid]="timegrid.validEnd"
                        [(ngModel)]="timegrid.end"
                        [ngModelOptions]="{standalone: true}"
                        (onChange)="onTimegridChange('end', $event)"
                        >
                      </mbsc-datepicker>
                      </nz-collapse-panel>
                      </nz-collapse>
                    </div> 
                  </div>
                

                </ng-container>
                <ng-template #footerTpl>
                  <div class="text-right">
                
                    <button 
                      nz-button 
                      nzType="primary"
                      
                        (click)="timegrid.visible = false; timegridVisibleChange(false);"
                        style=""
                        >
                  
                        {{ 'common.close' | actrans }}
                  
                      </button>

                    </div>
                </ng-template>
              </nz-drawer>

            </div>
            <div *ngIf="bas.ui.r.gtesm && false">

              <button 
                nz-button 
                nzBlock 
                [nzSize]="view.size"
                nz-dropdown 
                nzTrigger="click"  
                [nzDropdownMenu]="timegridPopup" 
                [(nzVisible)]="timegrid.visible"
                (nzVisibleChange)="timegridVisibleChange($event)" 
                class="text-left"
                style=""
                >
                <span nz-icon nzType="clock-circle"></span>
                <span class="text-lg-off"> {{ timegrid.label | actrans }}</span>
          
              </button>




            <nz-dropdown-menu #timegridPopup="nzDropdownMenu">
        
              <div nz-menu>
                <!-- <i nz-icon nzType="close" class="pull-right p-3" (click)="timegrid.visible = false; timegridVisibleChange(false);" ></i>  -->
        
                <div class="p-3-off m-d">
              

                <!-- TODO: mobil, bruke start-end som er standard i mobiscroll?  -->   
                  <div *ngFor="let ti of pw.timeItemsAvailable" nz-row [nzGutter]="16">
                    <div nz-col [nzXs]="24" [nzSm]="12">
                      <h2>{{ 'common.from' | actrans }}</h2>
                      <mbsc-datepicker 
    
                          [controls]="['timegrid']"
                          [display]="'inline'"
                          class="timegrid-timepicker inline-block w-100"
                          style=""
                          [minTime]="ti.startTimeAsString"
                          [maxTime]="ti.endTimeAsString"
                          [stepMinute]="ti.duration"
                          [valid]="timegrid.validStart"
                          [(ngModel)]="timegrid.start"
                          [ngModelOptions]="{standalone: true}"
                          (onChange)="onTimegridChange('start', $event)"
                          >
                      </mbsc-datepicker>

                      <!--

                        [display]="bas.ui.r.gtesm ? 'inline' : 'anchored'"
                      -->
                
                    </div> 
                    <div nz-col  [nzXs]="24" [nzSm]="12" style="border-left: 1px black solid;">
                      <h2>{{ 'common.to' | actrans }}</h2>
                      <mbsc-datepicker 
                        [controls]="['timegrid']"
                        [display]="'inline'"
                        class="timegrid-timepicker inline-block w-100"
                        style=""
                        [minTime]="ti.startTimeAsString"
                        [maxTime]="ti.endTimeAsString"
                        [stepMinute]="ti.duration"
                        [valid]="timegrid.validEnd"
                        [(ngModel)]="timegrid.end"
                        [ngModelOptions]="{standalone: true}"
                        (onChange)="onTimegridChange('end', $event)"
                        >
                      </mbsc-datepicker>
          
                    </div> 
                  </div>

                  <div class="text-right">
                    <button 
                      nz-button 
                    nzType="default"
                      [nzSize]="'small'"
                      (click)="timegrid.visible = false; timegridVisibleChange(false);"
                      style=""
                      >
                
                      {{ 'common.close' | actrans }}
                
                    </button>
                  </div>
      
            
                  
        
                </div>
              </div>
        
            </nz-dropdown-menu>

            </div>

      
          </nz-form-control>
        </nz-form-item>

      </div>

    }
      <div nz-col  
        [nzXs]="view.grid.guests.xs" 
        [nzSm]="view.grid.guests.sm" 
        [nzMd]="view.grid.guests.md" 
        [nzLg]="view.grid.guests.lg" 
        [nzXl]="view.grid.guests.xl" 
        [nzXXl]="view.grid.guests.xxl" 
        class=""
        *ngIf="pw.productObj.guestProduct"
        >

        <btlib-select-guests [pw]="pw" [gcs]="pw.guestCounts" (change)="onGuestsUpdate($event)" ></btlib-select-guests>
      </div>

      <div nz-col  
        [nzXs]="view.grid.guests.xs" 
        [nzSm]="view.grid.guests.sm" 
        [nzMd]="view.grid.guests.md" 
        [nzLg]="view.grid.guests.lg" 
        [nzXl]="view.grid.guests.xl" 
        [nzXXl]="view.grid.guests.xxl" 
        
        *ngIf="pw.productObj.findIsPriceCustom"
        >
        <nz-form-item>
          <nz-form-control class="custom-style" >
            <nz-input-number-group class="w-100"  [nzAddOnBefore]="'kr'">
              <nz-input-number 
                [(ngModel)]="pw.customAmountDouble" 
                [ngModelOptions]="{standalone: true}"
                [nzStep]="50"
                [nzMin]="bas.us.checkEmpty(pw.productObj.findCustomPriceMinDouble, 10)"
                [nzMax]="bas.us.checkEmpty(pw.productObj.findCustomPriceMaxDouble, bas.us.Infinity)"

                (ngModelChange)="onChange($event, 'customAmountDouble')"
                ></nz-input-number>
            </nz-input-number-group>

        
            
          </nz-form-control>
        </nz-form-item>
      </div>

      
      <div nz-col  
        [nzXs]="view.grid.guests.xs" 
        [nzSm]="view.grid.guests.sm" 
        [nzMd]="view.grid.guests.md" 
        [nzLg]="view.grid.guests.lg" 
        [nzXl]="view.grid.guests.xl" 
        [nzXXl]="view.grid.guests.xxl" 
        
        *ngIf="opts.showCampaignCode && pw.productObj.showCampaignCodeField"
        >
        <nz-form-item>
          <nz-form-label  class="">{{ 'bus.campaign.code' | actrans }}</nz-form-label>
          <nz-form-control class="custom-style" >

            <input nz-input
            [nzSize]="view.size"
            [(ngModel)]="pw.campaignCode" 
            [ngModelOptions]="{standalone: true}"
            (ngModelChange)="onChange($event, 'campaignCode')"
            />
            
          </nz-form-control>
        </nz-form-item>
      </div>

    </div>
  </form>




</div>
