@if (dmd.desc) {
  <div [innerHTML]="dmd.desc">

  </div>
}

@if (dmd.controls?.length) {
  <form nz-form [formGroup]="edit.form" class="item-form mt-8" nzLayout="vertical" [nzAutoTips]="bas.fs.autoTips" (ngSubmit)="submit()"  >
    <btlib-form-row [controls]="dmd.controls" [form]="edit.form"  [grid]="bas.ui.gridFull" ></btlib-form-row>

  </form>
} 

<!-- <div class="mt-d buttons text-right">
  <button nz-button nzType="primary" (click)="submit()">{{ 'app.lib.common.ok' }}</button>

</div> -->

