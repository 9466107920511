<div *ngIf="pw" class="bt-table">
  <table>
    <tbody>
      <tr *ngIf="bas.ui.r.xs">
        <td class="btt-label" colspan="2">
          {{ pw.findName }}

          <!-- <i 
          class="ml-d"
            *ngIf="pw.productObj.mkgCartItemInfo"
            nz-icon [nzType]="'info-circle'" 
            nz-tooltip 
            [nzTooltipTitle]="pw.productObj.mkgCartItemInfo"
            >
  
          </i> -->
          <div class="text-italic fgc-gray-40 text-sm" *ngIf="pw.productObj.mkgCartItemInfo">
            {{ pw.productObj.mkgCartItemInfo }}
          </div>
          

        </td>
      </tr>
      <tr >

      <td class="btt-label" *ngIf="bas.ui.r.gtesm">
        {{ pw.findName }}

        <!-- <i 
        class="ml-d"
          *ngIf="pw.productObj.mkgCartItemInfo"
          nz-icon [nzType]="'info-circle'" 
          nz-tooltip 
          [nzTooltipTitle]="pw.productObj.mkgCartItemInfo"
          >

        </i> -->
        <div class="text-italic fgc-gray-40 text-sm" *ngIf="pw.productObj.mkgCartItemInfo">
          {{ pw.productObj.mkgCartItemInfo }}
        </div>
      </td>
      <td class="" style="width: 120px;">
     
        @if (pw.showInput) {
          @if (pw.unlimited) {

            @if (pw.productObj.guestProduct && pw.productObj.gcsSize > 1) {

              <btlib-select-guests [pw]="pw" [gcs]="pw.guestCounts" (change)="onChange($event, 'guests')"></btlib-select-guests>

            } @else {
              <nz-input-number 
             
              [nzMin]="0"
              [nzMax]="pw.available || undefined"
              [nzStep]="1"
              [(ngModel)]="pw.quantity"
              (ngModelChange)="onChange($event, 'input')"
              style="width: 70px;"
            ></nz-input-number>
            }

          } @else {
            <div  class="text-center">
              <nz-switch
                [(ngModel)]="pw.checked"
                [nzCheckedChildren]="checkedTemplate"
                [nzUnCheckedChildren]="unCheckedTemplate"
                (ngModelChange)="onCheckbox($event)"
              ></nz-switch>
              <ng-template #checkedTemplate><span nz-icon nzType="check"></span></ng-template>
              <ng-template #unCheckedTemplate><span nz-icon nzType="close"></span></ng-template>
            </div>
          }
        }
 

      </td>
      <td class="text-right" style="width: 120px;">
        <div *ngIf="pw.showPrice">

          {{ pw.amountDouble | nf }}
        </div>
      </td>

</tr>
</tbody>
</table>
</div> 
