<div *ngIf="pw">

  <form nz-form [nzLayout]="'vertical'" class="m-d-off" >

    @if (!options.hidePeriodGuests) {
      <div>
        <btlib-pw-period-guests [pw]="pw" [opts]="{viewName: view.name }" (change)="onPeriodGuestsUpdate($event)"></btlib-pw-period-guests>
  
      </div>
    } 
    @if (options.showGuests && pw.productObj.guestProduct) {
      <div nz-row  [nzGutter]="bas.ui.gridMd.gutter" nzAlign="top" >
        <div nz-col  
          [nzXs]="view.grid.guests.xs" 
          [nzSm]="view.grid.guests.sm" 
          [nzMd]="view.grid.guests.md" 
          [nzLg]="view.grid.guests.lg" 
          [nzXl]="view.grid.guests.xl" 
          [nzXXl]="view.grid.guests.xxl" 
          class=""
          >

          <btlib-select-guests [pw]="pw" [gcs]="pw.guestCounts" (change)="onGuestsUpdate($event)" ></btlib-select-guests>
        </div>
      </div>

    }


    <div class="mt-d"></div>

    @if (pw.findTimeArea) {
  
      <bt-alert  class="mt-8" nzType="info"  [message]="('common.venue' | actrans | str) + ': ' + pw.findTimeArea.mkName " [description]="pw.findTimeArea.mkDesc" ></bt-alert>

      <!-- <nz-alert class="mt-8" nzType="info" [nzMessage]="('common.venue' | actrans | str) + ': ' + pw.findTimeArea.mkName " [nzDescription]="venueTmpl" nzShowIcon></nz-alert>
      <ng-template #venueTmpl>

        <div [innerHTML]="pw.findTimeArea.mkDesc"></div>
      </ng-template> -->

    }

    <div class="mt-d"></div>

    <div *ngIf="pw.errorMsg">
      <bt-alert  nzType="error" [mk]="pw.errorMsg" ></bt-alert>


    </div>

    <div *ngIf="!pw.errorMsg">



      <nz-alert
        *ngIf="pw.productObj.providerConfirmationRequired"
        class="mb-d"
        nzType="info"
        [nzMessage]="providerConfirmationRequiredMessage"
        nzShowIcon
      ></nz-alert>
      <ng-template #providerConfirmationRequiredMessage>
        <p [innerHTML]="'web.order.productInfo.providerConfirmationRequired' | actrans"></p>
      </ng-template>

      @if (!options.hideAvailablePoolUnits) {
      <div
        *ngIf="pw.availablePoolUnits"
        >
        <!-- TODO: flytte til app-pw-period-guests? -->
        <form nz-form [nzLayout]="'vertical'">

          
          <div nz-row  [nzGutter]="bas.ui.gridMd.gutter" nzAlign="top" >
            <div nz-col 
            [nzXs]="view.grid.guests.xs" 
            [nzSm]="view.grid.guests.sm" 
            [nzMd]="view.grid.guests.md" 

              >

              <nz-form-item>
                <nz-form-label  class="" nzFor="">{{ 'web.main.order.selectPoolUnit' | actrans }}</nz-form-label>
                <nz-form-control [nzErrorTip]="'web.main.order.selectPoolUnit' | actrans | str">
            
                  <nz-input-group nzPrefixIcon="home" style="padding: 3px 10px;">
      
                    <nz-select 
                    [(ngModel)]="pw.jsonData.poolUnitId"
                    [ngModelOptions]="{standalone: true}"
                    (ngModelChange)="onPoolUnitChange($event)"
                    >
                      <nz-option 
                        [nzValue]="''" 
                        [nzLabel]="'----- ' + ('web.main.order.selectPoolUnit.empty' | actrans) + ' ----'">
                      </nz-option>
                      <nz-option 
                        *ngFor="let apu of pw.availablePoolUnits || []" 
                        [nzValue]="apu.id" 
                        [nzLabel]="apu.mkName + (apu.amountAsDouble && pw.amountDouble  && apu.amountAsDouble != pw.amountDouble ? ': kr ' + (apu.amountAsDouble | nf) : '')">
                      </nz-option>
                    </nz-select>
              
                  </nz-input-group>
        
                </nz-form-control>
              </nz-form-item>

            </div>
          </div>
      
        </form>
      </div>
      }

      @if (!options.hideInfo) {
        <div class="bt-table">
          <table>
            <tbody>
              @if ( pw.amountDouble && !pw.amountEqualsRetailAmount) {
                <tr>
                  <td>
                    {{ 'common.amount' | actrans | str }}
                  </td>
                  <td class="text-right" [style.width]="bas.ui.r.gtesm ? '240px' : '50%'">
                    <span class="text-line-through fgc-red">{{ pw.retailAmountDouble | nf }}</span>
                   
                  </td>
                </tr>
                <tr>
                  <td class="fgc-green">
                    {{ 'common.discount' | actrans:{fallback:"Rabatt"} | str }}
                  </td>
                  <td  class="text-right fgc-green" [style.width]="bas.ui.r.gtesm ? '240px' : '50%'">
                    {{ -(pw.retailAmountDouble - pw.amountDouble ) | nf }}
                  </td>
                </tr>
              }
            @if (pw.productObj.tcDate && !pw.productObj.singleDateProduct) {
              <tr [class.fgc-green]="pw.amountDouble && !pw.amountEqualsRetailAmount">
                <td class="">
                  <span *ngIf="bas.ui.r.gtemd">{{ pw.productObj.mkName }}, </span>
                  {{ pw.days }} {{ 'common.days' | actrans | str | lowercase  }}
                </td>
                <td class="text-right text-bold" [style.width]="bas.ui.r.gtesm ? '240px' : '50%'">
                  <span *ngIf="pw.amountDouble">
                    {{ pw.amountDouble | nf }}
                  </span>
                  <span *ngIf="!pw.amountDouble">
                    {{ pw.periodString }}
                  </span>
                </td>
              </tr>
            } @else if (pw.productObj.tcTime || (pw.productObj.tcDate && pw.productObj.singleDateProduct)) {
              <tr [class.fgc-green]="pw.amountDouble && !pw.amountEqualsRetailAmount">
                <td class="">
                  {{ 'common.count' | actrans }}: {{ pw.quantity }} 
                </td>
                <td  class="text-right text-bold" [style.width]="bas.ui.r.gtesm ? '240px' : '50%'">
                  <span *ngIf="pw.amountDouble">
                    {{ pw.amountDouble | nf }}
                  </span>
                  <span *ngIf="!pw.amountDouble">
                    {{ pw.periodString }}
                  </span>
                </td>
              </tr>
            } @else {
              <tr [class.fgc-green]="pw.amountDouble && !pw.amountEqualsRetailAmount">
                <td class="">
                  {{ 'common.amount' | actrans }}
                </td>
                <td  class="text-right text-bold" [style.width]="bas.ui.r.gtesm ? '240px' : '50%'">
                  <span *ngIf="pw.amountDouble">
                    {{ pw.amountDouble | nf }}
                  </span>
                  <span *ngIf="!pw.amountDouble">
                    {{ pw.periodString }}
                  </span>
                </td>
              </tr>
            }
     
          
          </tbody>
    
        </table>
      </div>
    }

    <div *ngIf="pw.groupItems?.length">
      <btlib-cart-add-item *ngFor="let gi of  pw.groupItems" [pwp]="pw" [pw]="gi" [opts]="{type: 'groupItem'}" (change)="onItemChange($event, gi)" ></btlib-cart-add-item>
    </div>

    <div *ngIf="pw.packageItems?.length && pw.productObj.showChildItems">
      <!-- <div *ngFor="let pi of pw.packageItems">{{ pi.productObj.mkName }}</div> -->
      <btlib-cart-add-item *ngFor="let pi of  pw.packageItems" [pwp]="pw" [pw]="pi" [opts]="{type: 'packageItem'}" (change)="onItemChange($event, pi)" ></btlib-cart-add-item>
  
    </div>


    @if (pw.inputValuesList?.length && inputValues.obj) {
      <div class="mt-8">
        <!-- <h2>{{ 'common.info' }}</h2> -->

        <!-- <div nz-row>
          <div nz-col ></div>
        </div> -->
        <form nz-form [formGroup]="inputValues.form"  class="item-form " nzLayout="vertical" [nzAutoTips]="bas.fs.autoTips">
          
          <!-- <div *ngFor="let iv of pw.inputValuesList">
            <bt-dynamic-form-control  [control]="inputValues.controls[iv.extKey]" [form]="inputValues.form" [options]="{}"></bt-dynamic-form-control>

          </div> -->

          @if (inputValues.guestRows) {

            @for (key of inputValues.mapKeys; track key) {
              <btlib-form-row [controls]="inputValues.map[key] " [form]="inputValues.form"  [grid]="view.grid.guests"  (change)="onInputChange($event)" ></btlib-form-row>
            
            }

          } @else {

            <btlib-form-row [controls]="inputValues.list | filterBy: { data: { isGuestInput: false }}" [form]="inputValues.form"  [grid]="view.grid.guests" (change)="onInputChange($event)"  ></btlib-form-row>
            <btlib-form-row [controls]="inputValues.list | filterBy: { data: { isGuestInput: true }}" [form]="inputValues.form"  [grid]="view.grid.guests"  (change)="onInputChange($event)" ></btlib-form-row>
            
            
          }


      
        </form>

      </div>
    }


    @if (!options.hideAccessories && pw.accessories.length) {
      <div class="bt-table">
        <table>


          <tbody *ngIf="pw.accessoriesMandatory.length">
            <tr *ngFor="let acc of pw.accessoriesMandatory">
              <td class="btt-label">
                {{ acc.findName }}
              </td>
              <td  class="text-right">
                {{ acc.amountDouble | nf }}
              </td>
            </tr>
      
            <tr >
              <td class="btt-label">
                {{ 'common.sum' | actrans }}
              </td>
              <td class="text-right">
                {{ pw.totalAmountDouble | nf }}
              </td>
            </tr>
          </tbody>

        
        </table>
      </div>

      <div class="mt-8" *ngIf="pw.accessoriesOptional.length">
        <div >
          <h2>{{ 'app.cbsite.common.order.accessoriesOptional' | actrans }}</h2>
        </div>

        <btlib-cart-add-item *ngFor="let acc of  pw.accessoriesOptional" [pwp]="pw" [pw]="acc" [opts]="{type: 'accessory'}" (change)="onItemChange($event, acc)" ></btlib-cart-add-item>


        
      </div>
    }

      @if (!options.hideAmount && pw.calcAmount !== undefined) {
        
        <hr/>
        <div class="bt-table">
          <table>
            <tbody>
              <tr>
                <td class="btt-label">
                  {{ 'common.total' | actrans }}
                </td>
                <td class="text-right" [style.width]="bas.ui.r.gtesm ? '240px' : '50%'">
                  {{ pw.calcAmount | nf }}
                </td>
              </tr>
            </tbody>
        
          </table>
        </div>
      }



      <div>
        <!--
          TODO: relaterte produkter, gjerne som kan legges rett i handlevognen?
        -->
      </div>

    </div>

  </form>

</div>
