
<pinch-zoom disableZoomControl="disable" [limitPan]="true" backgroundColor="transparent">
  <div class="image-map-container" [ngStyle]="getContainerStyle()">
    <img [src]="bg.img" />
    <div class="image-map-item-container">
      <div 
        class="map-item" 
        [class.disabled]="item.disabled"
        [ngStyle]="getItemStyle(item)"
        [title]="item.name" 
        *ngFor="let item of items" 
        (click)="onItemClick(item)" 
   
        ></div>
    
    </div>
  </div>
  </pinch-zoom>

<!-- @if (edit.obj) {
  <form nz-form [formGroup]="edit.form" class="item-form mt-d" nzLayout="vertical" [nzAutoTips]="bas.fs.autoTips" (ngSubmit)="spaceSave()"  >
      
    <btlib-form-row [controls]="[ edit.controls.name ]" [form]="edit.form"  [grid]="bas.ui.gridMd"  ></btlib-form-row>
    <btlib-form-row [controls]="[ 
      edit.controls.spaceBooking.childMap.left,
      edit.controls.spaceBooking.childMap.top,
      edit.controls.spaceBooking.childMap.width,
      edit.controls.spaceBooking.childMap.height,
      edit.controls.spaceBooking.childMap.rotation 
      ]" [form]="edit.form.controls.spaceBooking"  [grid]="bas.ui.gridXs" ></btlib-form-row> 

    <button nz-button nzType="primary" nzSize="large" [nzLoading]="edit.loading" >{{ 'common.save' | actrans }}</button> 

  </form>


} -->

  