import { HostListener, inject } from '@angular/core';

import { Observable, BehaviorSubject, Subject, from } from 'rxjs';
import { MiscUtil } from '../util/misc.util';
import { BooktechAppService } from './booktech-app.service';


export const BtEvent = {
  "DS_INIT":        "DS_INIT",
  
  "APP_INIT":                   "APP_INIT",
  "APP_STATE_CHANGE":           "APP_STATE_CHANGE",

  "APP_HOOK_COMPLETE": "APP_HOOK_COMPLETE",

  "APP_READY":      "APP_READY",
  "APP_FIND_COMPANY": "APP_FIND_COMPANY",
  
  "MESSAGE":        "MESSAGE",
  "RESIZE":         "RESIZE",
  "DEVICE_RESIZE":  "DEVICE_RESIZE",

  "GOTO_CHECKOUT":  "GOTO_CHECKOUT",
  "GO_BACK":        "GO_BACK",

  "LibPwUpdate":        "LibPwUpdate",
  "LibCartAdd":        "LibCartAdd",
  "LibCartAddFormValidate": "LibCartAddFormValidate",
  // "LibPwUpdate":        "LibPwUpdate",

  //
  "KlaroConsentUpdate": "KlaroConsentUpdate",


  //
  "":"",
};


export class EventService {
  listeners: any;
  eventsSubject: any;
  events: any;

  isReady:boolean = false;

  constructor(public bas: BooktechAppService ) {
    if(this.bas.envtest) console.log('EventService');

    this.listeners = {};
    this.eventsSubject = new Subject();

    this.events = from(this.eventsSubject);

    this.events.subscribe( ({name, args}:any) => {
        if (this.listeners[name]) {
            for (let listener of this.listeners[name]) {
                listener(...args);
            }
        }
    });

    this.on(BtEvent.APP_READY, () => {
      if(this.bas.envtest) console.log(MiscUtil.getLogText('EventService: APP_READY fired'));
      this.isReady = true;

      
    });

    this.sendParentMessage({ action: "init" });

  }

  on(name:string, listener:any) {
    if (!this.listeners[name]) {
        this.listeners[name] = [];
    }

    this.listeners[name].push(listener);
  }

  off(name:string, listener:any) {
    this.listeners[name] = this.listeners[name].filter((x:any) => x != listener);
  }

  trigger(name:string, ...args:any[]) {
    this.eventsSubject.next({
        name,
        args
    });
  }


  ready():Promise<void> {
    return new Promise<void>((res, rej) => {
      if (this.isReady) return res();

      this.on(BtEvent.APP_READY, () => {
        res();
      });
    });
  }

  // $btapi.conf.target.iframe.contentWindow.postMessage("cb;1000;ACTION;" + JSON.stringify(params), "*"); 
  onMessage(event:any):any {
   
    let data = event.data;
    if (typeof data !== "string") {
      if(this.bas.envtest) console.log(MiscUtil.getLogText('EventService: onMessage, data is not string: '), data);
     

      if(this.bas.envtest) this.trigger(BtEvent.MESSAGE, { 
        success: false,
        error: "data is not string",
        data: data
      });

      return { error: "data is not string" };
    }

    var arr = data.split(";");
		if (arr.length < 3) {
			//$api.debug("arr.length < 3, s: "+ s + ", l: " + arr.legth);
      if(this.bas.envtest) console.log(MiscUtil.getLogText('EventService: onMessage, arr.length < 3: '), data);
     
      if(this.bas.envtest) this.trigger(BtEvent.MESSAGE, { 
        success: false,
        error: "arr.length < 3",
        data: data
      });

			return { error: "arr.length < 3"};

		}
		
		var prefix = arr[0];
		var wid = arr[1];
		var action = arr[2];
		var arg3 = arr[3];
		var params = arg3 ? JSON.parse(arg3) : { };

    let btEvent = {
      success: true,
      prefix: prefix, 
      wid: wid,
      action: action,
      params: params
    }

    if(this.bas.envtest) console.log("btEvent: ", btEvent);

    this.trigger(BtEvent.MESSAGE, btEvent);
 
    return btEvent;
  }

  sendParentMessage(message:any) {
    if (this.bas.ssr) return;

    let win:any = window;
    
    if (win.parentIFrame) {
      if(this.bas.envtest) console.log("sendParentMessage.parent: ", win.parentIFrame, ", message: " + message);
      win.parentIFrame.sendMessage(message);
    }
    
    
  }
  sendParentMessageResize() {
    this.sendParentMessage({ action: "resize" });
  }
  sendParentMessageRedir(url:string) {
    if (this.bas.us.isIframe()) this.sendParentMessage({ action: "redirect", target: url }); //TODO:v7
    else this.bas.us.redirect(url);
  }
  sendParentMessageScroll() {
    this.sendParentMessage({ action: "scroll" });
  }

 
}
