
import { Component, Injector, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MiscUtil } from '../../../util/misc.util';

 
import { DynformControl } from '../../../model/dymform-control';

import { inject } from '@angular/core';

import { LibModulesWolcModule } from '../../../components/lib-modules-wolc.module';
import { BooktechAppService } from "../../../services/booktech-app.service";
import { BtEvent } from '../../../services/event.service';

import { BtCartAddItemComponent } from '../cart-add-item/cart-add-item.component';
import { BtPwPeriodGuestsComponent } from '../pw-period-guests/pw-period-guests.component';

import { BtSelectGuestsComponent } from '../select-guests/select-guests.component';

import { BtFormRowComponent } from '../../form/bt-form-row/bt-form-row.component';
import { BtAlertComponent } from '../../ui/bt-alert/bt-alert.component';

@Component({
  selector: 'btlib-cart-add-form',
  standalone: true,
  imports: [ 
    LibModulesWolcModule, 
    BtSelectGuestsComponent,
    BtCartAddItemComponent, 
    BtPwPeriodGuestsComponent,

    BtFormRowComponent,
    BtAlertComponent
   ],
  templateUrl: './cart-add-form.component.html',
  styleUrl: './cart-add-form.component.scss'
})
export class BtCartAddFormComponent {
  bas = inject(BooktechAppService);


  // @Input() pw:any;
  @Output() change = new EventEmitter<any>();

  @Input() options:any = { };
  
  @Input() 
  set pw(pw: any) {
    if(this.bas.envtest) console.log("Input.set.pw: ", pw);

    if (!pw) {
      this.pw = undefined;
      return;
    }

    this.setPw(pw);

  }
  get pw() { return this._pw; }

  _pw:any = undefined;
  inputValues:any = {
    list: [],
    map: { },
  }  
 

  
  views:any = {
    default: {
      name: "default",
      grid: {
        date:     { xs: 24, sm: 24, md: 24, lg: 24, xl: 24,  xxl: 24,  gutter: 16 },
        guests:   { xs: 24, sm: 24, md: 24, lg: 24, xl: 24,  xxl: 24,  gutter: 16 },
      },
      size: "default"
    },
    fullWidth: {
      name: "fullWidth",
      grid: {
        date:     { xs: 24, sm: 8, md: 8, lg: 8, xl: 8,  xxl: 8,  gutter: 16 },
        guests:   { xs: 24, sm: 8, md: 8, lg: 8, xl: 8,  xxl: 8,  gutter: 16 },
      },
      size: "large"
    }
  };
  view = this.views.fullWidth;

  constructor() {


    // if(this.bas.envtest) console.log("AppCartAddFormComponent");


  }

  ngOnInit(): void {


    this.bas.es.on(BtEvent.LibCartAddFormValidate, (args:any) => {
      if (args.id && args.id != this.pw.id) {
        // if(this.bas.envtest) console.log("CartAddFormValidate, id does not match: " + args.id + " != " + this.pw.id);
        return;
      }
      this.validate();

    });

    if (this.options.viewName) {
      switch (this.options.viewName) {
        case "fullWidth": this.view = this.views.fullWidth; break;
        case "default": this.view = this.views.default; break;
      }
 
    }

  }

  
  onPeriodGuestsUpdate(event:any) {
    if (!event.field) {
      return;
    }
    if(this.bas.envtest) console.log("onPeriodGuestsUpdate, event: ", event);

    if (event.field == "periodAsRange") {
      let parts = event.value.split("_");
      
      this.pw.startDateAsString = parts[0];
      this.pw.endDateAsString = parts[1];
      // this.pw.periodArray = MiscUtil.dateRangeToDates(event.value)
    }

    this.updatePw(event.field);

  }
 
  onGuestsUpdate(event:any) {
    if(this.bas.envtest) console.log("onGuestsUpdate, event: ", event);
    // this.emitChange({ field: "guestCounts", value: event.value });


    this.updatePw(event.field);
  }

  async updatePw(sourceField:string) {

    let skipUpdatePw = !!this.options.skipUpdatePw;
    let skipUpdatePwData = !!this.options.skipUpdatePwData;

    let pw = this.pw;

    if (skipUpdatePw) {

      pw = skipUpdatePwData ? pw : await this.bas.os.getPwData(pw, [ "core", "timeItemsAvailable", "amount"]);

      this.setPw(pw);
  


    } else {
      if(this.bas.envtest) console.log("BtCartAddForm.setPw: ", this.pw);
      let res = await this.bas.os.updatePw(this.pw);
      pw = res.pw;
      if(this.bas.envtest) console.log("BtCartAddForm.setPw.after: ", this.pw);
      this.setPw(pw);
  
    }

   
    this.change.emit({ field: "pw", sourceField: sourceField, value: pw, skipUpdatePw: skipUpdatePw });
  }

  setPw(pw:any) {
    if(this.bas.envtest) console.log("AppCartAddForm.setPw: ", pw);
    pw = this.bas.os.updatePwObj(pw);

    // if(this.bas.envtest) console.log("AppCartAddForm.setPw: ", pw);
    pw.accessories = pw.accessories || [];
    // pw.accessories.map((acc:any) => acc.parentObj = pw );
    pw.accessoriesMandatory = pw.accessories.filter((acc:any) => acc.findMandatory);
    pw.accessoriesOptional = pw.accessories.filter((acc:any) => !acc.findMandatory);



    this.updateInputValues(pw)

    this._pw = pw;
    this.updateAmount();
  }

  onPoolUnitChange($event:Event) {
    if(this.bas.envtest) console.log("onPoolUnitChange, ev: ", $event);

    let pw = this.pw;
    pw.orgAmount = pw.amountDouble;
    
    let apu = $event && this.pw.availablePoolUnits.find((i:any) => i.id == $event);


    pw.poolUnit = apu;
    pw.amountDouble = apu ? apu.amountAsDouble : pw.orgAmount ;
    this.updateAmount();
  }

  onItemChange($event:Event, item:any) {
    // if(this.bas.envtest) console.log("onAccChange, ev: " + $event + ", acc: ", acc);
    // acc.amountDouble = acc.unitAmount * acc.quantity;

    this.updateAmount();
  }

  updateAmount() {
    let pw = this.pw;
    let amount = pw.amountDouble;
    if (amount === undefined || amount === "") return;
    
    
    for (let pi of pw.partItems || []) {
      amount += pi.amountDouble;
    }

    for (let acc of pw.accessoriesMandatory) {
      amount += acc.amountDouble;
    }
    for (let acc of pw.accessoriesOptional) {
      amount += acc.amountDouble;
    }

    if (pw.groupItems?.length) {
      for (let gi of pw.groupItems) {
        amount += gi.amountDouble;
      }
    }
    pw.calcAmount = amount;
  }

  // submit() {
  //   this.change.emit({ 
  //     action: "submit"
  //   });
  // }

    
  // drawerClose() {
  //   this.pw = undefined; // TODO: kan ikke åpne den ignen for set-funksjonen kalles ikke
  // }

  validate() {
    let rv = this.inputValues.obj ? this.bas.fs.getRawValue(this.inputValues.form, this.inputValues.controls) : true;

    this.pw.isValid = rv !== false;

    if(this.bas.envtest) console.log("validate, isValid: " + this.pw.isValid + ", rv: ", rv);

    if (!this.pw.isValid) return false;

    this.pw.jsonData.inputValuesMap = rv;
   
    this.change.emit({ field: "isValid", value: this.pw.isValid , pw: this.pw });

    return true;
  }

  // addToCart() {
  //   if (!this.validate()) return;
  //   this.bas.es.trigger(AppEvent.CartAdd, { pw: this.pw });
  //   this.drawerClose();
  // }


  // checkout() {
  //   if (!this.validate()) return;
  //   this.bas.es.trigger(AppEvent.CartAdd, { pw: this.pw });

  //   this.bas.ui.router.navigate([ 
  //     this.bas.ui.getRouterPrefix() 
  //       + '/checkout'
  //     ], { queryParams: { 
  //       // id: "test" 
  //     }}
  //   );
  // }


  updateInputValues(pw:any){
    let controls: DynformControl[] = [];

    let ivs = this.inputValues;
    ivs.map = { };

    if(pw.inputValuesList) {
      let rv = ivs.obj ? this.bas.fs.getRawValue(ivs.form, ivs.controls, true) : (pw.jsonData?.inputValuesMap || { });

      for (let iv of pw.inputValuesList || []) {

        // let ct = "input";


        let io = iv.inputObj;
        let type = io.typeEnum;
        let isGuestInput = io.guestInput;

        let mk = io.mkName;

        if (isGuestInput) mk = "#" + iv.mkIndex + ": " + iv.mkGc + ": " + mk;

        let ctl = new DynformControl({  
          controlType: "input",
          key: iv.extKey, 
          mk: mk,
          required: io.required,
          value: rv[iv.extKey] || iv.value || io.mkDefaultValue,
          data: {
            isGuestInput: isGuestInput
          }
        });

        if(this.bas.envtest) console.log("updateInputValues, key: " + ctl.key + ", value: " + ctl.value + ", rv: ", rv);
        
        switch (type.enumName) {
          case "Checkbox": 
            ctl.controlType = "checkbox"; 
            ctl.value = io.mkDefaultValue === "true" ? true : false;
            break;
          case "TextArea": 
            ctl.controlType = "textarea"; 
            ctl.data.height = 30;
            break;
          case "Select": 
            ctl.controlType =  "select"; 
            ctl.optionsList = (io.optionsAsList || []).map((item:string) => { return  { label: item, value: item } });
            ctl.optionsAllowEmpty = true;
            break;
        }
 
        controls.push(ctl);

        ivs.map[iv.index] = ivs.map[iv.index] || [ ];
        ivs.map[iv.index].push(ctl);
        
        if (iv.index >= 0 && ivs.map[iv.index].length > 1) ivs.guestRows = true;
      }
      /*

      */
    }

    ivs.mapKeys = Object.keys(ivs.map).sort();
    ivs.list = controls;
    this.bas.fs.updateFormObj(this.inputValues, controls, { });
  }


  onInputChange($event:any) {
    if (!$event.control || !this.inputValues.obj) return;

    if(this.bas.envtest) console.log("onInputChange, ev: ", $event);
    // if(this.bas.envtest) console.log("onInputChange, key: " + ctl.key + ", value: " + ctl.value + ", rv: ", rv);
    
    let rv = this.bas.fs.getRawValue(this.inputValues.form, this.inputValues.controls, true, false);

    this.pw.jsonData.inputValuesMap = rv;
    
  }

}
