import { Component, Input, OnInit, Injector, EventEmitter, Output } from '@angular/core';

import { inject } from '@angular/core';

import { LibModulesWolcModule } from '../../../components/lib-modules-wolc.module';
import { BooktechAppService } from "../../../services/booktech-app.service";
import { NzAlign, NzJustify } from 'ng-zorro-antd/flex';


@Component({
  selector: 'bt-image-row',
  standalone: true,
  imports: [ LibModulesWolcModule ],
  templateUrl: './bt-image-row.component.html',
  styleUrl: './bt-image-row.component.scss'
})
export class BtImageRowComponent {
  bas = inject(BooktechAppService);

  @Input() urls: string[] =  [ ];
  @Input() opts: any = { };
  @Input() nzAlign:NzAlign = "flex-start";
  @Input() nzJustify:NzJustify = "center";

  constructor() {

  }

  ngOnInit(): void {


  }

}


