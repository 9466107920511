
@if (bas.ui.r.ltesm && urls.length) {

  <img nz-image class="img-single border-radius-d w-100" [nzSrc]="urls[0]"  alt="" />

  <!-- <div class="img-blur-wrapper">
    <img  class="img-blur" [src]="urls[0]"  alt="" />
    <img nz-image class="img-single border-radius-d" [nzSrc]="urls[0]"  alt="" />

  </div> -->

} @else {

  <div
    class=""
    style="overflow: hidden;"
    nz-flex

    [nzJustify]="nzJustify"
    [nzAlign]="nzAlign"
    nzGap="small"
    >
    @for (url of urls; track url) {
      <img  nz-image [nzSrc]="url" [style]="opts.style" />
    } 

  </div>

}


