
<div *ngIf="b && data.paymentId == undefined">

  <nz-spin [nzSpinning]="data.loading">

  <!-- <div *ngIf="bas.envtest">
    opts: {{ opts | stringify }}
  </div> -->
  
  @if (b.isCancelled) {


    <!-- <p>{{ 'web.iframe.payment.info.cancelled' | actrans }}</p> -->
    <nz-alert class="mt-d" 
          nzType="error" 
          [nzMessage]="'web.iframe.payment.info.cancelled' | actrans | str" nzShowIcon></nz-alert> 
  }
  @else if (b.remainingAmountIncludePendingDouble <= 0) {

    <p>{{ 'app.lib.component.payment.noRemaining' | actrans }}</p>
  } 
  @else if (b.remainingAmountIncludePendingDouble > 0) {

    @if (opts.showOrderTable && o) {
      <div class="bt-table mt-d">
        <table>
          <thead>
            <tr>
              <td class="btt-label">
                {{ 'common.text' | actrans}}
              </td>
              <td class="text-right text-nowrap" style="width: 120px;">
                {{ 'common.amount' | actrans}}
              </td>
      
            </tr>
          </thead>
          <tbody>
            @for (bi of o.biList; track bi) {

              <tr>
                <td class="btt-label">
                  {{ bi.pName }} {{ bi.periodString	}}
                
                </td>
                <td class="text-right text-nowrap">
                  {{ (bi.amountAsDouble) | nf }}
                  <!-- {{ (ci.calcAmount || ci.totalAmountDouble) | nf }} -->
                
                </td>
        
              </tr>

              <!-- @if (ci.cartItems?.length) {

                @for ( item of (ci.cartItems) | filterBy: { }; track item) {
                  <tr>
                    <td class="btt-label">
                      <div class=" pl-8">{{ item.findName }}</div>
                    </td>
                    <td  class="text-right">
                      {{ item.amountDouble | nf }}
                    </td>
                  </tr>
                }
             
              } -->
           

            }
      
          </tbody>
          <!-- <tfoot>
            <tr>
              <td class="btt-label">
                {{ 'common.sum' | actrans}}
              </td>
              <td class="text-right text-nowrap">
                {{ cart.sum | nf }}
              </td>
        
            </tr>
          </tfoot> -->
        </table>

      </div>
    }


    <div *ngIf="opts.showSumTable" class="bt-table mt-d">
      <table>
        <tbody>
          <tr>
            <td class="btt-label">
              {{ 'app.lib.component.payment.orderAmount' | actrans }}
              
            </td>
            <td class="text-right text-nowrap">
              kr {{ b.amountDouble | nf }}
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="b.paidAmountIncludePendingDouble > 0">
          <tr>
            <td class="btt-label">
              
              {{ 'app.lib.component.payment.paidAmount' | actrans }}
            </td>
            <td class="text-right text-nowrap">
              kr {{ b.paidAmountIncludePendingDouble | nf }}
            </td>
          </tr>
          <tr>
            <td class="btt-label">
          
              {{ 'app.lib.component.payment.remainingAmount' | actrans }}
            </td>
            <td class="text-right text-nowrap">
              kr {{ b.remainingAmountIncludePendingDouble | nf }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  
    <button 
      nz-button 
      nzType="default" 
      *ngIf="!b.isGiftCardOrder && opts.showGiftCardButton === true"
      class="mt-d "
      [class.pull-left]="data.view == 'fixedAmount' || data.view == 'default' "
      (click)="$event.preventDefault(); data.useGiftCartVisible = true; ">
      {{ 'app.lib.component.payment.useGiftCard' | actrans }}
    </button>
  
   
    <!-- <p *ngIf="b.paidAmount == 0">
      Betalt beløp: kr {{ b.paidAmountDouble | nf }}
    </p> -->


    <div class="mt-d "></div>
    <ng-container [ngSwitch]="data.view">
      <div *ngSwitchCase="'customAmount'" >

        <div nz-row  [nzGutter]="[16,8]">
    
          
          <div nz-col  [nzXs]="24" [nzSm]="8"  [nzMd]="8" [nzLg]="6"  [nzXl]="5" >
            <nz-slider
              [nzMin]="data.minAmount" 
              [nzMax]="b.remainingAmountIncludePendingDouble" 
              [nzStep]="data.step" 
              [(ngModel)]="data.customAmount"></nz-slider>
          </div>
          <div nz-col  [nzXs]="8" [nzSm]="6"  [nzMd]="4"  [nzLg]="4" [nzXl]="3"  >
            <nz-input-number
              [nzMin]="data.minAmount"
              [nzMax]="b.remainingAmountIncludePendingDouble"
          
              [nzStep]="data.step"
              [(ngModel)]="data.customAmount"
              class="w-100"
            ></nz-input-number>
        
          </div>
          
          <div nz-col  [nzXs]="16" [nzSm]="10"  [nzMd]="12"  [nzLg]="8" [nzXl]="7" >
            <button nz-button nzType="primary" nzBlock (click)="$event.preventDefault();  pay('custom'); ">{{ 'app.lib.component.payment.pay.custom' | actrans }}<span *ngIf="bas.ui.r.gtemd">: kr {{ data.customAmount | nf  }}</span></button>
  
          </div>
        </div>

      </div>
      
      <div *ngSwitchCase="'fixedAmount'">
        <!-- fixedAmount -->
         <div  class="mt-d text-right">
          <button nz-button nzType="primary" (click)="$event.preventDefault();  pay('custom'); ">{{ 'app.lib.common.pay' | actrans }}<span *ngIf="bas.ui.r.gtemd">: kr {{ data.customAmount | nf  }}</span></button>
      
          <button nz-button nzType="default" class="ml-d" (click)="$event.preventDefault(); pay('all'); ">{{ 'app.lib.common.pay' | actrans }} kr {{ b.remainingAmountDouble | nf  }}</button>
    
         </div>
      
      </div>
      <div *ngSwitchCase="'apInvoice'">

        <div class="bt-table mt-d">
          <table>
            <tbody>
              <tr>
                <td class="btt-label">
                  {{ 'app.lib.component.payment.deposit' | actrans }}
                  <!-- <span> betales nå</span> -->
                </td>
                <td class="text-right text-nowrap"><span class="fgc-red">kr {{ data.depositAmount | nf  }}</span></td>
                <!-- <td class=""> betales nå</td> -->
              </tr>
              <tr>
                <td class="btt-label">
                  {{ 'app.lib.component.payment.remaining' | actrans }}  {{ i?.dueDateFpAsDate || b.dueDateFpAsDate || b.findDepositDate  }}.
                  <!-- <span> betales nå eller senest {{ i?.dueDateFpAsDate || b.dueDateFpAsDate || b.findDepositDate  }}.</span></td> -->
                <td class="text-right text-nowrap"><span class="fgc-red">kr {{ (b.remainingAmountIncludePendingDouble - data.depositAmount )  | nf  }}</span></td>
                <!-- <td class=""> betales nå eller senest 6 uker før avreise</td> -->
              </tr>
              
            </tbody>
          </table>
        </div>

        <div class="m-d"></div>
          
        <div>
          <div nz-row  [nzGutter]="[16,8]">
    
            <!-- <div nz-col  [nzXs]="24" [nzSm]="24"  [nzMd]="24" [nzLg]="0" > </div> -->
            <div nz-col  [nzXs]="24" [nzSm]="14"  [nzMd]="12" [nzLg]="10" [nzXl]="8"  >
              <button nz-button nzType="default" nzBlock (click)="$event.preventDefault();  pay('advance'); ">{{ 'app.lib.component.payment.pay.deposit' | actrans }}: kr {{ data.depositAmount | nf  }}</button>
    
            </div>
            <div nz-col  [nzXs]="24" [nzSm]="10"  [nzMd]="12" [nzLg]="8" [nzXl]="7" >
              <button nz-button nzType="primary" nzBlock (click)="$event.preventDefault();  pay('all');  " class="ml-d-off">{{ 'app.lib.component.payment.pay.all' | actrans }}, kr {{ b.remainingAmountDouble | nf  }}</button>
    
            </div>
          </div>
          <div class="m-d"></div>
          
          <div nz-row  [nzGutter]="[16,8]">
    
          
            <div nz-col  [nzXs]="24" [nzSm]="8"  [nzMd]="8" [nzLg]="6"  [nzXl]="5" >
              <nz-slider
                [nzMin]="data.depositAmount" 
                [nzMax]="b.remainingAmountIncludePendingDouble" 
                [nzStep]="data.step" 
                [(ngModel)]="data.customAmount"></nz-slider>
            </div>
            <div nz-col  [nzXs]="8" [nzSm]="6"  [nzMd]="4"  [nzLg]="4" [nzXl]="3"  >
              <nz-input-number
                [nzMin]="data.depositAmount"
                [nzMax]="b.remainingAmountIncludePendingDouble"
            
                [nzStep]="data.step"
                [(ngModel)]="data.customAmount"
                class="w-100"
              ></nz-input-number>
          
            </div>
            
            <div nz-col  [nzXs]="16" [nzSm]="10"  [nzMd]="12"  [nzLg]="8" [nzXl]="7" >
              <button nz-button nzType="default" nzBlock (click)="$event.preventDefault();  pay('custom'); ">{{ 'app.lib.component.payment.pay.custom' | actrans }}<span *ngIf="bas.ui.r.gtemd">: kr {{ data.customAmount | nf  }}</span></button>
    
            </div>
          </div>
        </div>

        <!-- <nz-alert class="mt-d" 
          nzType="info" 
          nzMessage="Hvis du ønkser å benytte deg av delbetaling så anbefaler vi at du velger å betale alt nå slik at du kan velge delbetaling på hele summen." nzShowIcon></nz-alert> -->
          <!-- TODO: bare hvis delbetaling er mulig -->
        <!-- <p>Hvis du ønkser å benytte deg av delbetaling så anbefaler vi at du velger å betale alt nå slik at du kan velge delbetaling på hele summen.</p> -->
        
    
      </div>
      <div *ngSwitchCase="'default'" class="mt-d text-right">
        <!-- <p><span class="fgc-red">kr {{ b.remainingAmountDouble | nf  }}</span>, betales nå</p> -->
      
        <button nz-button nzType="primary" (click)="$event.preventDefault(); pay('all'); " class="">{{ 'app.lib.common.pay' | actrans }} kr {{ b.remainingAmountIncludePendingDouble | nf  }}</button>
    
      </div>
    </ng-container>

  
    
  
  <!-- </div> -->
  }

  </nz-spin>

</div>

<!-- <p class="bold">
  <nit:message key="web.order.payment.eula"/>  <br/>        
  <a class="mt0 showEulaLink btn btn-default btn-xs" href="#" style=""><nit:message key="web.ajax.register.showEula" ct="false" /></a> 
          
</p> -->
<div  *ngIf="!data.completed">
  <!-- <p class="text-bold">
    {{ 'web.order.payment.eula' | actrans }}

  </p> -->
  <div id="dibs-checkout-content">

  </div>
</div>

<div id="completed-content" *ngIf="data.completed">
  <!-- <div *ngIf="bas.envtest">
    TEST:
    <pre>
      {{ bas.us.stringify( data.det, 2 ) }}
    </pre>
  </div> -->

</div>

<nz-modal 
  [(nzVisible)]="data.useGiftCartVisible" 
  [nzTitle]="'app.lib.common.giftCard' | actrans | str" 

  [nzCancelText]="null"
  [nzOkText]="null"
  [nzFooter]="null"
  (nzOnOk)="$event.preventDefault(); useGiftcardCode(); "
  (nzOnCancel)=" data.useGiftCartVisible = false; "
  >
  <ng-container *nzModalContent>
    
    <div>
      <nz-input-group nzSearch nzSize="large" [nzAddOnAfter]="suffixButton">
        <input type="text"  [(ngModel)]="data.useGiftCartValue" nz-input [placeholder]="'app.lib.common.giftCard.code' | actrans | str" />
      </nz-input-group>
      <ng-template #suffixButton>
        <button nz-button nzType="primary" nzSize="large" nzSearch   [nzLoading]="data.useGiftCartLoading" (click)="useGiftcardCode()">{{ 'app.lib.component.payment.useGiftCard' | actrans }}</button>
      </ng-template>

    </div>


  </ng-container>
</nz-modal>
