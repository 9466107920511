import { inject } from '@angular/core';
import { BooktechAppService } from './booktech-app.service';

import { ActivatedRouteSnapshot } from '@angular/router';

import { setOptions, MbscPopupOptions, localeEn, localeNo } from '@mobiscroll/angular-ivy';

import { en_US, nb_NO, NzI18nService } from 'ng-zorro-antd/i18n';
import { DATAID } from './data.service';

// import { FilterPipe } from 'ngx-filter-pipe';
import { MiscUtil } from '../util/misc.util';
// import { FilterPipe } from '../pipes/ngx-filter.pipe';

const NzLangMap:any = {
  "no": nb_NO,
  "en": en_US
}


const MsLangMap:any = {
  "no": localeNo,
  "en": localeEn
}

export class UtilService {

  i18n: NzI18nService = inject(NzI18nService);
  ars:ActivatedRouteSnapshot | undefined = undefined;
  // filter:FilterPipe;

  public Infinity = Infinity

  constructor( public bas: BooktechAppService ) {

    if(this.bas.envtest) console.log("UtilService.constructor");

    // this.filter = injector.get(FilterPipe);

    setOptions({
      theme: 'ios' // 'mobiscroll',
      // themeVariant: 'light'
    });

    // this.i18n = injector.get(NzI18nService);
    let lang =  this.bas.ds.findLang() || this.bas.ds.lang.code || "no";
    this.setLang(lang);

    // console.log("lang: ", this.i18n.getLocale());

  }

  setLang(lang:string) {
    let nzLang = NzLangMap[lang];
    let msLang = MsLangMap[lang];
    if (!nzLang) {
      lang = "no";
      nzLang = NzLangMap[lang];
      msLang = NzLangMap[lang];
    }

    this.i18n.setLocale(nzLang);
    setOptions({
      locale: msLang
    });

  }

  testSetLocale() {
    this.setLang("en");
  }

  checkActivatedRoute(ars:ActivatedRouteSnapshot) {
    if (!ars || this.ars) return;

    this.ars = ars; 
    if(this.bas.envtest) console.log("checkActivatedRoute, ars.params: ", ars.params);
    

    let cid = ars.params['cid']
    if (!this.bas.standalone && cid && this.bas.ds.config.cid && cid != this.bas.ds.config.cid) {
      if(this.bas.envtest) console.log("checkActivatedRoute, cid has changed: " + cid + " -> " + this.bas.ds.config.cid);
    
      this.bas.ds.save(DATAID.APP_LOGIN, { success: false });
      this.bas.ds.save(DATAID.APP_CONFIG, { cid: cid }).then((res) => {
        if(this.bas.envtest) console.log("checkActivatedRoute, reloading, res: ", res);
        if (this.bas.nossr) window.location.reload();
      });

      return;
    }


    this.bas.ds.set(DATAID.APP_URL_PARAMS, ars.params || { });
    this.bas.ds.set(DATAID.APP_URL_QUERY_PARAMS, ars.queryParams || { });
    // this.bas.ds.set(DATAID.APP_URL_PATHNAME, window.location.pathname);
    this.bas.ds.set(DATAID.APP_URL_PATHNAME, this.bas.LOC.path());

    this.bas.style.updateCustomStyle(ars);


    // console.log("ars.urL: " + window.location.href);

  }

  goHome() {
    if (this.bas.nossr) window.location.href = "/";
  }
  reload() {
    if (this.bas.nossr) window.location.reload();
  }

  async delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
 
  stringify(obj:any, space:number = 0) {
    return JSON.stringify(obj, undefined, space);
  }


  pretty(input:string) {
    try {
      let json = JSON.parse(input);
      if (json) {
        let pretty = JSON.stringify(json, undefined, 2);
        return pretty;
      }
    } catch (err) {

    }
    return input;
  }

  keys(obj:any): string[] {
    return Object.keys(obj);
  }
  values(obj:any, exclude?:any): any[] {
    let values = Object.values(obj);
    if (!exclude)  return values;
    let list:any[] = [];


    let filtered = values.filter((item:any) => {
      if (typeof item  == "object") {
        //console.log("values item.id != exclude: " + (item.id != exclude) + "");
        if (item.id == exclude) return false; 
      }

      return true;
    });

    // console.log("values: " + values.length + " -> " + filtered.length + ", exclude: " + exclude + ", obj: ", obj);

    return filtered;
  }

  objectKeys(obj:any, sort:boolean = true):string[] {
    if (!obj) return [];

    let keys = Object.keys(obj);

    if (!sort) return keys;

    return keys.sort();
  }
  objectValues(obj:any, sortByKey:boolean = true): any[] {
    if (!obj) return [];

    if (!sortByKey) return Object.values(obj);

    let keys = this.objectKeys(obj);

    let values:any[] = [];
    keys.forEach((key) => {
      values.push(obj[key]);
    })
    return values;
  }


  listToMap(list:any[], key:string = "id"):any {
    return MiscUtil.listToMap(list, key);
  }

  mapToList(map:any, sortOrder:any[] = []) {
    return MiscUtil.mapToList(map, sortOrder);

  }

  isIframe() {
    return this.bas.nossr && window.location !== window.parent.location;
  }

  redirect(url:string) {
    if (this.bas.ssr) {
      this.bas.LOC.go(url)
      return;
    }
    if (this.bas.nossr) window.location.href = url;
  }

  pushState(url:string, obj?:any, title?:any) {
    try {
      history.pushState(obj || { }, title || "", url);
    } catch (err) { }
    
  }
  replaceState(url:string, obj?:any, title?:any) {
    try {
      history.replaceState(obj || { }, title || "", url);
      // TODO: this.bas.LOC.replaceState(this.bas.ui.getRouterPrefix(), this.bas.ws.getParamsAsString(urlParams));
    } catch (err) { }
  }

  nullIfEmpty(value:any) {
    if (value === "") return null;
    return value;
  }

  checkEmpty(value:any, valIfEmpty:any)  {
    if (value === "") return valIfEmpty;
    return value;
  } 

  isNumber(value:any) {
    return typeof value === "number";
  }
  abs(value:number) {
    return Math.abs(value);
  }

  validateEmail(email: string): boolean {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  }


  isValidDate(date: Date): boolean {
    return date instanceof Date && !isNaN(date.getTime());
  }

  sortFn(field:string) {
    // if(this.bas.envtest) console.log("sortFn, field: " + field);
    return (a: any, b: any) => {
      let av = field.indexOf(".") < 0 ?  a[field] : MiscUtil.getOwnNestedProperty(a, field);
      let bv = field.indexOf(".") < 0 ?  b[field] : MiscUtil.getOwnNestedProperty(b, field);

      // if(this.bas.envtest) console.log("sortFn, field: " + field + ", av: " + av + ", bv: " + bv);
      if ( av === undefined ||  bv === undefined) return bv === undefined ? 0 : -1;

      if (typeof av=== 'string' ) return av.localeCompare(bv);
      else return av - bv;
    } 
  }

}
