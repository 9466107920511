import { Component, OnInit, Input, Output, EventEmitter, inject } from '@angular/core';

import { LibModulesWolcModule } from '../../../components/lib-modules-wolc.module';
import { BooktechAppService } from "../../../services/booktech-app.service";

import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';
import { DynformControl } from '../../../model/dymform-control';


import { BtFormRowComponent } from '../../form/bt-form-row/bt-form-row.component';

export class ImageMapItem {
  coords:({top: number,
    left: number,
    width: number,
    height: number,
    rotation?: number
  }) = { 
    top: 0, 
    left: 0, 
    width: 0, 
    height: 0,
    rotation: 0
  };
  
  id?:any
  name?: string
  bg?:any = { }
  data?:any = { }
  selected?:boolean = false;
  disabled?:boolean = false;

  constructor(init?: Partial<ImageMapItem>) {
    Object.assign(this, init);
  }

}


@Component({
  selector: 'bt-image-map',
  standalone: true,
  imports: [ LibModulesWolcModule, PinchZoomModule, BtFormRowComponent ],
  templateUrl: './image-map.component.html',
  styleUrl: './image-map.component.scss'
})
export class ImageMapComponent {
  bas = inject(BooktechAppService);

  @Input()
  bg: any = {
    
  }

  @Input()
  items: ImageMapItem[] = []

  @Input()
  opts: any = {
    canEdit: false,
    obj: undefined
  };

  edit: any = {

  }

  @Output()
  onClick: EventEmitter<ImageMapItem> = new EventEmitter();

  @Output()
  onSave: EventEmitter<any> = new EventEmitter();

  selectedItem:ImageMapItem|null = null;

  constructor() {

  }

  ngOnInit() {

    // if (this.bas.envtest && this.bas.nossr && this.opts.canEdit) {

      


    //   window.addEventListener("keyup", (event:KeyboardEvent) => {
    //     // console.log("keyup: ", event);
    //     // For at ikke siden skal skrolle, først skroll til riktig sted: 
    //     // body { overflow: hidden;  }

    //     if (this.selectedItem == null) return;

    //     let coords = this.selectedItem.coords;

    //     if (event.key == "ArrowLeft") {
    //       coords.left -= (event.shiftKey ? 1 : 0.1);
    //     } else if (event.key == "ArrowRight") {
    //       coords.left +=  (event.shiftKey ? 1 : 0.1);
    //     } else if (event.key == "ArrowUp") {
    //       coords.top -=  (event.shiftKey ? 1 : 0.1);
    //     } else if (event.key == "ArrowDown") {
    //       coords.top +=  (event.shiftKey ? 1 : 0.1);
    //     } else 
        
    //     if (event.key == "a") {
    //       coords.width -=  (event.shiftKey ? 1 : 0.1);
    //     } else if (event.key == "d") {
    //       coords.width +=  (event.shiftKey ? 1 : 0.1);
    //     } else if (event.key == "w") {
    //       coords.height -=  (event.shiftKey ? 1 : 0.1);
    //     } else if (event.key == "s") {
    //       coords.height +=  (event.shiftKey ? 1 : 0.1);
    //     } else 
        
    //     if (event.key == "r") { // rotering
    //       if (coords.rotation == undefined) coords.rotation = 0.0;
    //       coords.rotation -= 1;
    //     } else if (event.key == "t") {
    //       if (coords.rotation == undefined) coords.rotation = 0.0;
    //       coords.rotation += 1;
    //     } else {
    //       return;
    //     }
    //     event.preventDefault();
    //     event.stopPropagation();
    //   });
    // }
  }

  getContainerStyle():object {

    let bg = this.bg;
    if (bg.img) {

      return {
        // backgroundRepeat: 'no-repeat',
        // backgroundImage: 'url("'+bg.img+'")',
        // backgroundSize: 'contain',
        height: bg.height || 'auto'
      }
     
    }

    return {
      backgroundColor: bg.color || 'transparent',
      height: bg.height || 'auto'
    }
  }

  getItemStyle(item: ImageMapItem): object {
    let color = item.selected ? item.bg.colorSelected || item.bg.color : item.bg.color ;
    if (item.disabled) color = item.bg.colorDisabled || "rgba(255, 0, 0, 0.4)";

    let style = {
      top: `${item.coords.top}%`,
      left: `${item.coords.left}%`,
      height: `${item.coords.height}%`,
      width: `${item.coords.width}%`,
      backgroundColor: color || 'transparent',
      transform: `rotate(${item.coords.rotation}deg)`,
    };

    return style;
  }

  onItemClick(item:any) {
    if (item.disabled) {
      if (!this.bas.envtest) return;
    }


    if (this.opts.skipSelect) {
      this.onClick.emit(item);
      return;
    }

    item.selected = !item.selected;
    this.onClick.emit(item);

    this.selectedItem = item;
  }


  // editObject(obj:any) {

  //   if(this.bas.envtest) console.log("editObject, obj: ", obj);

  //   // if (obj.jsonDataObj?.spaceBooking) {
  //   //   obj.spaceBooking = obj.jsonDataObj.spaceBooking;
  //   // }


  //   let controls: DynformControl[] = [];

  //   controls.push(new DynformControl({    key: 'name',   mk: 'common.name' }));


  //   controls.push(new DynformControl({ 
  //     key: 'spaceBooking',
  //     controlType: 'formGroup',
  //     children: [
  //       new DynformControl({ key: 'top',    mk: 'Fra toppen (%)', type: "number", required: true }),
  //       new DynformControl({ key: 'left',   mk: 'Fra venstre (%)', type: "number", required: true }),
  //       new DynformControl({ key: 'height',  mk: 'Høyde (%)', type: "number", required: true }),
  //       new DynformControl({ key: 'width',   mk: 'Bredde (%)', type: "number", required: true }),
  //       new DynformControl({ key: 'rotation',   mk: 'Rotasjon (°)', type: "number", required: false }),

  //      ]
  //   }));


  //   /*
  // { name: 'Plass 1',  top: 17.5, left: 80,   height: 8,  width: 4.5,             bg: this.itemBg},
  //   */

  //   this.bas.fs.updateFormObj(this.edit, controls, obj, true);
  // } 

  // spaceSave() {

  // }
}


