
import { Component, Injector, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MiscUtil } from '../../../util/misc.util';

 
import { DynformControl } from '../../../model/dymform-control';

import { inject } from '@angular/core';

import { LibModulesWolcModule } from '../../../components/lib-modules-wolc.module';
import { BooktechAppService } from "../../../services/booktech-app.service";


@Component({
  selector: 'btlib-select-guests',
  standalone: true,
  imports: [ LibModulesWolcModule ],
  templateUrl: './select-guests.component.html',
  styleUrl: './select-guests.component.scss'
})
export class BtSelectGuestsComponent {
  bas = inject(BooktechAppService);

  // route:ActivatedRoute;

  @Input() opts:any = { };
  @Input() pw:any;
  @Input() product:any;
  @Input() gcs:any = {

  };
  @Output() change = new EventEmitter<any>();

  view:any = {
    size: "large"
  }

  categories:any[] = [];
  guestsPopupVisible = false;

  constructor() {
    // this.route = inject(ActivatedRoute);
    // this.bas.us.checkActivatedRoute(this.route.snapshot);

    if(this.bas.envtest) console.log("BtSelectGuestsComponent");


  }

  ngOnInit(): void {
    let p = this.pw?.productObj || this.product; 

    this.product = p;
    // if(this.bas.envtest) console.log("BtSelectGuestsComponent.ngOnInit, p: ", p);

    if (p?.mkGcs) {
      let maxGcMap = p.maxGcMap || { };

      let cats:any[] = [];
      // if(this.bas.envtest) console.log("BtSelectGuestsComponent.ngOnInit, maxGcMap: ", maxGcMap);

      for (let idx = 0; idx < 5; idx++) {
        let gcName = p.mkGcs[idx];
        // if(this.bas.envtest) console.log("idx: "+idx+", gcName: " + gcName + ", mkGcs: ", p.mkGcs);

        if (maxGcMap[idx] === 0) continue;

        if (gcName === undefined) break;
        cats.push({
          mk: gcName,
          idx: idx,
          max: maxGcMap[idx] || 100
        })
        
      }

      this.categories = cats;

      return;
    }

    this.categories = [{
      mk: "bus.bookingItem.gc0" ,
      idx: 0,
      max: 30
    },{
      mk: "bus.bookingItem.gc1" ,
      idx: 1,
      max: 30
    }];
  }

  //
  incrementGcValue(gcIdx:number) { this.setGcValue(gcIdx, this.gcs.map[gcIdx] + 1); }
  decrementGcValue(gcIdx:number) { this.setGcValue(gcIdx, this.gcs.map[gcIdx] - 1); }
  setGcValue(gcIdx:number, value:number) {
    if (value < 0) return;

    let p = this.pw?.productObj || this.product; 

    let maxGc = p?.maxGcMap[gcIdx];
    // if(this.bas.envtest) console.log("setGcValue, maxGc: " + maxGc);

    if (maxGc === undefined || maxGc === "") maxGc = 100;
    if (this.pw?.maxGuests) maxGc = Math.min(maxGc, this.pw?.maxGuests);

    // if(this.bas.envtest) console.log("setGcValue, maxGc: " + maxGc);
    

    if (maxGc < value) return;

    this.gcs.map[gcIdx] = value;
    MiscUtil.updateGuestCountsFromMap(this.gcs);

    //TODO: sjekke total antall mot maxGuests?



  }

  onGcChange(gcIdx:number ) {
    if(this.bas.envtest) console.log("onGcChange, val: " +  this.gcs.map[gcIdx]);
    
    let val =  this.gcs.map[gcIdx];
    if (val) {
      this.setGcValue(gcIdx, val);
    }

  }

  guestsPopupClose() {
    this.guestsPopupVisible = false;
    this.guestsPopupVisibleChange(false);
  }
  guestsPopupVisibleChange(event:any) {
    if(this.bas.envtest) console.log("guestsPopupVisibleChange, ev: ", event);
    if (event === false) {
      this.change.emit({ field: "guestCounts", value: this.gcs });
    }
  }

  getGcs() {
    let gcs = this.gcs.guestCountsAsString.split(",").join(" + ");
    return gcs;
  }


}



